import React, { useState } from 'react';
import Styles from './About.module.css';
import Person from '../../assets/man.jpg';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Collapse } from '@mui/material';
import AboutBg from '../../assets/AboutUs.jpg';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GreenButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#00cc66",
  border: "2px solid #00cc66",
  '&:hover': {
    backgroundColor: "#00cc66",
  },
}));

//import { ProgressBar } from 'react-bootstrap';

function CollapsableCard() {
  const [show, setShow] = useState(false)
  return (
    <>
      <div className={Styles.CardList} onClick={() => setShow(!show)}>
        <MdOutlineKeyboardArrowRight className={Styles.Icon} /> <label className={Styles.CardListTitle}>Accordion Heading Text Item # 1</label>
      </div>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <p className={Styles.CardListP}>Sed ut perspiciaatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.</p>
      </Collapse>
    </>
  )
}



function ProgressBarComp({ bgcolor, progress, height, text }) {
  const Parentdiv = {
    height: height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,

  }

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right'
  }

  const Heading = {
    margin: '0'
  }

  return (
    <>
      <label className={Styles.ProgressHeading}>{text}</label>
      <div style={Parentdiv}>
        <div style={Childdiv}>

        </div>
      </div>
    </>
  )
}

const About = () => {
    const navigate = useNavigate()
  return (
    <>
      <div>
        <div className={Styles.AboutImageContainer}>
          <div className={Styles.AboutImage}>
            <div className={Styles.AboutTextContainer}>
              <label className={Styles.AboutText}>ABOUT US</label>
              <label className={Styles.AboutSubText}>A Team Of Coders</label>
            </div></div>

        </div>
        <div className={Styles.SectionOneContainer}>
          <div className={Styles.SectionOneHeadingOneContainer}>
            <label className={Styles.SectionOneHeadingOne}>WE MEET OUR TARGETS</label>
          </div>
          <div className={Styles.SectionOneHeadingTwoContainer}>
            <label className={Styles.SectionOneHeadingTwo}>We are a team of coders, strategists and product owners who are keen to know their real customers. Our team is always up for challenges which comes out of fast technology changes in the industry and help you create the product with end use case in mind.</label>
          </div>
        </div>
        <div className={Styles.SectionTwoContainer}>
          <div className={Styles.SectionSubTwo}>
            <label>A BRIEF ABOUT OUR COMPANY :</label>
            <p>
              We at Echno understand that every business is different and needs unique technology approach to cover this diversity. Our major focus remains to know our customer business and commit to the success alongwith our customers. We are committed to the success of our customer and their business.
            </p>
            <p>
              Our philosophy is to support our customers as well as gain trust of our employees. Everyone at
              Echno whether technical or non-technical staff is committed to the growth of the company. This
              in turn leads to the customer satisfaction as well as our growth alongwith employees and
              customers.
            </p>
            <p>
              At work, we do not believe to create any complexity. While dealing with data, our focus remains
              to get the architecture as simple and intuitive as possible. This consistently results in data
              driving us to actions in an immediate and effective manner.

            </p>
            <p>
              Our company believes in professionalism, transparency and to be in good relations with customer.
              Our dedicated technologists makes sure of regular communication, quality and in time delivery to
              customers.
            </p>

          </div>
        </div>
 
        <div className={Styles.CareerSection}>
        <div className={Styles.CareerImage}>
        </div>
        <div className={Styles.CareerInfo}>
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingOne}>Life @Echno</label>
          </div>
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingTwo}>Make an Impact</label>
          </div> 
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingThree}>We focus on investing on our employees, retain them, nurture a creative environment & help them find a way to innovate</label>
          </div>

          <div className={Styles.CareerButton}>
              <GreenButton onClick={()=>navigate('\career')}>Join Us</GreenButton>
          </div>
        </div>
      </div>
      
      </div>
    </>

  )
}

export default About