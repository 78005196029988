import React from 'react'
import Styles from './Services.module.css'
import { BsBell } from 'react-icons/bs';
import { SiMicrostrategy } from 'react-icons/si';
import { GiReceiveMoney } from 'react-icons/gi';
import { FaRegLightbulb,FaRecycle } from 'react-icons/fa';
import { BsArrowsAngleContract } from 'react-icons/bs';
import { BiSupport } from 'react-icons/bi';
import {GiSpiderWeb} from 'react-icons/gi';
import {FaPeopleArrows} from 'react-icons/fa';
import {AiFillDatabase} from 'react-icons/ai';
import {GiTrophyCup} from 'react-icons/gi';
import {GrDatabase} from 'react-icons/gr'
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import {IoMdAnalytics} from 'react-icons/io';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BugReportIcon from '@mui/icons-material/BugReport';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import './services.scss'


const ServicesPage = () => {
    return (
        <>
        <div className="ServicesContainer">

       
            <div className={Styles.ServiceHeader}>
                <label className={Styles.ServiceHeaderTitle}>Our Services</label>
            </div>
            <div className={Styles.container}>
                <div className={Styles.One}>
                    {/* <label className={Styles.OneTitle}>Our Best Services</label> */}
                    <p className={Styles.OneText}>Echno is a technology company with a strength to build cutting edge software. Backed by a team of skilled and dedicated software developers, we help you transform your vision into reality. Our team is capable of delivering technology solutions and add value to your business. Here are the services we offer :</p>
                    {/* <p className={Styles.OneText}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt eius magni provident, doloribus omnis minus ovident, doloribus omnis minus temporibus perferendis nesciunt..</p> */}
                </div>

                <div className={Styles.MidContainer}>
                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <FaRecycle className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Product Development</label>
                                  <p className={Styles.MidLastContainerText}>Our Product development services include Front End (Mobile & Web development) and Cloud applications development and deployment.</p>
                                  <p className={Styles.MidLastContainerText}>For startups, We work with our customers from the ideation till deployment and carry over to the maintenance phase. For Enterprises, we can unblock the current deficiencies in the system and maintain the product though out.</p>
                                  <p className={Styles.MidLastContainerText}> Our proficiency in various technologies helps us to narrow down over the technology stack which is suitable for the product. We walk with our customers to begin the journey with ideation, design, development, deployment and enjoy being part of success story.</p>
                                  
                             </div>
                       </div>
                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <CloudDoneIcon className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Cloud Computing</label>
                                  <p className={Styles.MidLastContainerText}>We offer cloud computing services which includes primarily server infrastructure, database, storage, networking, application development, analytics, optimize usage of various services offered by cloud service providers. We are good to optimize cost, make the product secure and global, scalable design, use encryptions as needed and faster performance to achieve.</p>
                                  <p className={Styles.MidLastContainerText}>Products in today world needs latest technologies such as containers, dockers, kubernetes, Rest API communication, serverless architecture with a Devops and we are good at this.</p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <AiFillDatabase className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Data Engineering</label>
                                  <p className={Styles.MidLastContainerText}>We identify the customer requirements, work on a scalable and efficient design, implement ETL/ELT process, define and implement if there is need of data warehosuing, verify the quality of data and deliver a solution. We understand and work upon different services over cloud - analytical databases eg Bigquery & redshift, ETL eg Databricks & DataFlow, scalable compuite engines eg EC2, Visualization tools eg PowerBI and Tableau.</p>
                                  <p className={Styles.MidLastContainerText}>
                                  The tools are supported by programmers for technologies Python, Scala, Java etc. End of the day, we support our customers to structurely store the data, get a meaningful insight, become a data-driven organization to make swift business decisions.
                                  </p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <DashboardIcon className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Business Intelligence & Visualization</label>
                                  <p className={Styles.MidLastContainerText}>Data visualization is the practice of converting raw data into a graphic format. The process takes care to show logical correlations between units, define inclinations, tendencies, and patterns. As we get the visualization customized to what we need.</p>
                                  <p className={Styles.MidLastContainerText}> Business Intelligence on the other side brings out the right information to accelerate decision making. We connect business process with tools, creating a powerful and information rich experience. It further helps in deploying predictive ML algorithms to predict future trends, mitigating risks and enhancing productivity.</p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <IoMdAnalytics className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Data Analytics</label>
                                  <p className={Styles.MidLastContainerText}>Modernize your analytics with cloud, flexible architectures, and the latest technologies. Streamline business insights from data while reducing costs. Data quality manifests reporting, metric formulation, business definitions, and reconciliation of data.</p>
                                  <p className={Styles.MidLastContainerText}> We can help you to reinforce quality across data governance, master data management, and technology components. We offer support and maintenance services specifically for Data & Analytics solutions. This includes SLAs, partial support, as well as architecture and consulting services, We’ll bring the right talent, customized to your unique requirements.</p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <EmojiObjectsIcon className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>UI/UX Design</label>
                                  <p className={Styles.MidLastContainerText}>Visual representation reflects the core identity of your product to the whole world. As the user interaction with the products and company is increasing digitally day by day, User experience depends entirely on the interface.</p>
                                  <p className={Styles.MidLastContainerText}>
                                  We provide Custom UX approach which increases Users engagement and time spent on the product. We deploy latest technology to design UX / UI for mobile as well as for web. Our process includes 5 stage approach - Research, Strategy, Design, Execute & Deploy. Our UX strategy aligns with customer / product brand identity as well touchbase customer at every end point where interface needs an enrichment.
                                  </p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <BugReportIcon className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Software Testing Services</label>
                                  <p className={Styles.MidLastContainerText}>Businesses today needs a testing approach that is dynamic, customized and flexible to accelerate the progress. With the open source industry significant contribution towards test automation in terms of framework, design and implementation - it becomes affordable for companies to move towards the same. </p>
                                  <p className={Styles.MidLastContainerText}>
                                  Cloud-based Test Automation has gained traction in recent times where we can achieve Continuous Automation and Continuous Delivery. Though Acceptance Test driven Development and Performance Automation in an Agile environment is a need of the hour.
                                  </p>
                             </div>
                       </div>

                       <div className={Styles.MidCard}>
                       <div className={Styles.MidCardIconContainer}>
                           <div className={Styles.RootIconContainer}>
                                <PrecisionManufacturingIcon className={Styles.RootIcon}/>
                            </div>
                       </div>   
                            
                             <div className={Styles.MidLastContainer}>
                                  <label className={Styles.MidLastContainerTitle}>Robotic Process Automation</label>
                                  <p className={Styles.MidLastContainerText}>Robotic Process Automation (RPA) is the technology that allows organizations to automate any manual repetetive tasks. It works upon to simulate a human being interacting with a traditional computermaking data entries. The two leading RPA tools are BluePrism and UiPath. With the time, it has become a disruptive technology to see a significant and immediate gain into the business world.</p>
                                  <p className={Styles.MidLastContainerText}>
                                  Software robots can connect to the system APIs, scrape data from web, access read / write to databases, use natural language processing to extract contents from documents, make calculations and other.
                                  </p>
                             </div>
                       </div>

                </div>

              
                
            </div>
            </div>
        </>
    )
}

export default ServicesPage