import React from 'react';
import Styles from './App.module.css';
import { MdOutlineCall } from 'react-icons/md'
import { BiTime } from 'react-icons/bi'
import Navbar from './components/Navbar/navbar';
import ImageSlider from './components/ImageSlider/imageslider';
import Services from './components/services/services';
import Organization from './components/organization/Organization';
import Footer from './components/Footer/footer';
import About from './pages/About/About';
import {
  MemoryRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home/Home';
import { MissingRoute } from './components/Redirect'
import ServicesPage from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Career from './pages/Career/Career';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import {IoLogoWhatsapp} from 'react-icons/io5'
import { WhatsappUrl } from './baseUrl';

const App = () => {
  return (
    <div className={Styles.container}>
      <div>
        <div className={Styles.ContactNavabarContainer}>
          <div className={Styles.ContactNavabarContainerInfo}>
            {/* <BiTime className={Styles.ContactNavabarContainerInfoIcon} /><label className={Styles.ContactNavabarContainerInfoTitle}>Mon - Fri 10AM - 7PM.</label> */}
          </div>

          <div className={Styles.ContactNavbarInfoContainer}>
            <div className={Styles.ContactNavabarContainerInfo}>
              <MdOutlineCall className={Styles.ContactNavabarContainerInfoIcon} />   <label className={Styles.ContactNavabarContainerInfoTitle}>+91-{process.env.REACT_APP_CONTACT}</label>
            </div>
            <div>
              <span className={Styles.span}>|</span>
            </div>
         
            <div className={Styles.ConnectUS}>
            <a href={WhatsappUrl} target="_blank"><IoLogoWhatsapp className={Styles.ContactNavabarContainerWhatsappIcon}/></a>
            <a href={WhatsappUrl} target="_blank"><label className={Styles.ConnectUSTitle}>Connect</label> </a>
            
            </div>

          </div>

        </div>
        <Navbar />



        <Routes>
          <React.Fragment>
            <Route exact path="*" element={<MissingRoute />} />
          </React.Fragment>
          <React.Fragment>   <Route exact path="/" element={<Home />}></Route></React.Fragment>
          <React.Fragment> <Route exact path="/about" element={<About />}></Route></React.Fragment>
          <React.Fragment> <Route exact path="/services" element={<ServicesPage />}></Route></React.Fragment>
          <React.Fragment> <Route exact path="/contactus" element={<Contact />}></Route></React.Fragment>
          <React.Fragment> <Route exact path="/career" element={<Career />}></Route></React.Fragment>
          <React.Fragment> <Route exact path="/privacypolicy" element={<PrivacyPolicy />}></Route></React.Fragment>

        </Routes>
      </div>



      <Footer />
    </div>
  )
}

export default App