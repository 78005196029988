import axios from 'axios';
import { baseUrl } from '../baseUrl';

const Authaxios = axios.create({
    // baseURL:baseUrl,
    headers:{
        "Content-type":'application/json',
        "Access-Control-Allow-Origin": "*",
    }
})

export const SendEmailContact=async(param)=>{
      console.log(param)
    return new Promise(async(resolve,reject)=>{
         try {
        const Data =await axios.post('https://4zfjt8khgh.execute-api.ap-south-1.amazonaws.com/echno-website/contact',param,{
            headers:{
                "Access-Control-Allow-Origin":"*"
            }
        });
        console.log(Data)
        resolve(Data)
    } catch (error) {
        console.log(error)
        reject(error);

    }
    })
   
}