import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import One from '../../assets/images1.jpg'
import Two from '../../assets/images2.jpg';
import Styles from './ImageSlider.module.css'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ImageSlider = () => {
    return (
        <div>
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={false}
                draggable={false}
                autoPlaySpeed={4000}
                infinite={true}
                // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
                <div className={Styles.Container}>
                    <div className={Styles.ImagContainer}>
                        {/* <img src={One} /> */}
                    </div>
                    <div className={Styles.AboveImage}>
                        <label className={Styles.headingOne}>Empowering Digital Transformation</label>
                        <label className={Styles.headingTwo}>CONSULTING | STRATEGY | ANALYSIS</label>
                    </div>
                </div>
                <div className={Styles.Container}>
                    <div className={Styles.ImagOneContainer}>
                        {/* <img src={One} /> */}
                    </div>
                    <div className={Styles.AboveImage}>
                        <label className={Styles.headingOneData}>Business Intelligence and Data Analytics Services</label>
                        <label className={Styles.headingTwoData}>DATA ENGINEERING | DATA WAREHOUSING | DATA VISUALIZATION</label>
                    </div>
                </div>


                <div className={Styles.Container}>
                    <div className={Styles.ImagTwoContainer}>
                        {/* <img src={One} /> */}
                    </div>
                    <div className={Styles.AboveImage}>
                        <label className={Styles.headingOne}>Delivering Integrated Solution</label>
                        <label className={Styles.headingTwo}>CLOUD | FRONT END | DATA | ML</label>
                    </div>
                </div>

            </Carousel>
        </div>
    )
}

export default ImageSlider