import React, { useState } from 'react';
import Styles from './Career.module.css';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import { MdCancel } from 'react-icons/md';
import { Collapse } from '@mui/material';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { FaLaptopCode } from 'react-icons/fa';
import { BiRupee } from 'react-icons/bi';
import { IoLocationSharp } from 'react-icons/io5';
import { MdStickyNote2 } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SendEmailCareer } from '../../actions/careerAction';
import axios from 'axios';
import { baseUrl } from '../../baseUrl';
// import {env} from 'process'

const RedButton = styled(Button)(({ theme }) => ({
  color: "#002b80",
  backgroundColor: "#f6ce4b",
  border: "2px solid #f6ce4b",
  '&:hover': {
    backgroundColor: "#f6ce4b",
  },
}));

function CollapsableCard({ item }) {
  const [error, setError] = useState({})
  const [show, setShow] = useState(false)
  const [file, setFile] = useState('');
  const [text, setText] = useState('')




  const onFileSelect = (e) => {
    let xl = e.target.files[0];
    setFile(xl);
  }


  const handleRemove = () => {
    setFile('')
  }


  const IsValid = () => {
    let success = true;
    let error = {}
    if (!text.trim()) {
      error.name = "Name cannot be empty"
      success = false;
    }
    if (file === undefined || file === null || file === '') {
      error.file = "Please Choose a File"
      success = false;
    }
    else if (file && file.name && !file.name.trim()) {
      error.file = "Please Choose a File`1"
      success = false;
    }
    setError(error);
    return success
  }

  const Submit = async (e) => {
    e.preventDefault();
     if(IsValid()){
    let formData = new FormData();
    formData.append('file', file);
    formData.append('name', text);
    formData.append('auth',`${process.env.REACT_APP_AUTH_KEY}`);
    
    const ResumeUpload = async (formData) => {

      return new Promise(async(resolve,reject)=>{
            try {
              await axios.post(`${baseUrl}echno-uploadResume`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                }
              }).then(res => {
                resolve(res.data)
        
              }).catch(err => reject(err.message))
            } catch (error) {
                 reject(error)
            }
      })
      
        
    }


    const data = new Promise(async (resolve) => {
      await ResumeUpload(formData).then((json) => {
        if (json.code === 200) {
            setText('');
            setFile('');
            formData = new FormData();
        }
        setTimeout(() => { resolve(json) }, 2000)
      })
    });

    

    ResumeUpload(formData)

    // if(IsValid()){
    // console.log(param)
    // const data = new Promise(async (resolve) => {
    //   await SendEmailCareer(formData).then((json) => {
    //     if (json.code === 200) {
    //         setText('');
    //         setFile('');
    //         formData = new FormData();
    //     }
    //     setTimeout(() => { resolve(json) }, 2000)
    //   })
    // }
    // );


    const response = await toast.promise(
      data,
      {
        pending: 'Sending Mail',
        success: 'Mail Sent SuccessFully 👌',
        error: 'Something Went Wrong!! Please Try Again🤯'
      }
    );
    setError({})
    }
  }
  return (
    <>
      <div className={Styles.Card} onClick={() => setShow(!show)}>
        <label className={Styles.JobTitle}>{item.jobTitle}</label>

        <div className={Styles.ExpContainer}>
          <div className={Styles.ExpList}>
            <FaLaptopCode className={Styles.ExpListIcon} /><label className={Styles.ExpListTitle}> {item.exp} Years</label>
          </div>

          {/* <div className={Styles.ExpList}>
            <BiRupee className={Styles.ExpListIcon} /><label className={Styles.ExpListTitle}>{item.salary}</label>
          </div> */}

          <div className={Styles.ExpList}>
            <IoLocationSharp className={Styles.ExpListIcon} /><label className={Styles.ExpListTitle}>{item.location}</label>
          </div>

        </div>

        <div className={Styles.ExpList}>
          <MdStickyNote2 className={Styles.ExpListIcon} /> <label className={Styles.ExpListTitle}>{item.Educational}</label>
        </div>

        <div className={Styles.Languages}>
          {
            item.skills.map((skill, index) => (
              <><label className={Styles.LanguagesTitle}>{skill}</label>
                <label className={Styles.LanguagesTitleDot}>.</label></>

            ))
          }

        </div>

        {/* <label className={Styles.JobTitle}>Details</label> */}
      </div>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <div className={Styles.container}>
          <div>
            {/* <label className={Styles.JobDescription}>Job Description</label> */}

            <div className={Styles.ResponsibilitiesContainer}>
              <label className={Styles.SmallHeading}>Job Description</label>
              <ul className={Styles.ResponsibilitiesUl}>
                {
                  item.Responsibilities.map((res, index) => (
                    <li key={index} className={Styles.Responsibilitiesli}>{res.title}</li>
                  ))
                }
              </ul>
            </div>
          </div>

          <div>
            <label className={Styles.SmallHeading}>Educational Requirements</label>
            <ul className={Styles.ResponsibilitiesUl}>
              <li className={Styles.Responsibilitiesli}>{item.Educational}</li>
            </ul>

          </div>
{/* 
          <div>
            <label className={Styles.SmallHeading}>Technical and Professional Requirements</label>
            <ul className={Styles.ResponsibilitiesUl}>
              <li className={Styles.Responsibilitiesli}>{item.technical}</li>
            </ul>
          </div> */}

          {/* <div>
            <label className={Styles.SmallHeading}>Preferred Skills</label>
            <ul className={Styles.ResponsibilitiesUl}>
              <li className={Styles.Responsibilitiesli}>{item.preferred}</li>
            </ul>
          </div> */}

          {/* <div className={Styles.RoleListContainer}>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>Role</label> <label className={Styles.RoleListText}>{item.Role}</label></div>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>Industry Type</label> <label className={Styles.RoleListText}>{item.IndustryType}</label></div>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>Functional Area</label> <label className={Styles.RoleListText}>{item.FunctionalArea}</label></div>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>Employement Type</label> <label className={Styles.RoleListText}>{item.EmployementType}</label></div>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>Role Category</label> <label className={Styles.RoleListText}>{item.RoleCategory}</label></div>
          </div>

          <div className={Styles.RoleListContainer}>
            <label className={Styles.SmallHeading}>Education</label>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>UG</label> <label className={Styles.RoleListText}>{item.UG}</label></div>
            <div className={Styles.RoleList}><label className={Styles.RoleListTitle}>PG</label> <label className={Styles.RoleListText}>{item.PG}</label></div>
          </div> */}

          <div className={Styles.RoleListContainer}>
            <label className={Styles.SmallHeading}>Key Skills</label>
            <div className={Styles.SkillsContainer}>
              {
                item.skills.map((skill, index) => (
                  <label className={Styles.Skills}>{skill}</label>
                ))
              }
            </div>
          </div>

          <label className={Styles.HeadingTwo}>SEND YOUR CV TO:  </label>
          <label className={Styles.SkillsContainer}> contact@echnotek.com</label>
          {/* <form onSubmit={Submit}>


            <div className={Styles.InputList}>
              <div className={Styles.ListContainer}>
                <TextField label="Full Name" size='small' variant="outlined" value={text} autoComplete='off' onChange={(e) => setText(e.target.value)} />
                {error.name && <span className={Styles.Error}>**{error.name}**</span>}
              </div>
              {
              //<TextField label="Email" size='small' variant="outlined" />
            //<TextField label="Mobile No" size='small' variant="outlined" /> 
            }

              <div className={Styles.ResumeUploadContainer}>
                <div className={Styles.UploadContainer}>
                  <label className={Styles.UploadTitle}>Resume</label>
                  <div className={Styles.ListContainer}>
                    <input type="file" accept=".doc,.pdf" variant="standard"  className={Styles.Choose} id='resume' onChange={onFileSelect} />

                  </div>

                  <div className={Styles.ListContainer}>
                    <div className={Styles.FileNameContainer}>
                      <label htmlFor='resume' className={Styles.ResumeUpload}>Upload</label>
                      {
                        file.name && file.name !== undefined && file.name !== null ? (

                          <label className={Styles.FileNameTitle}>{file.name} <MdCancel className={Styles.FileNameIconCancel} onClick={handleRemove} /></label>


                        ) : (
                          null
                        )
                      }

                    </div>
                    {error.file && <span className={Styles.Error}>**{error.file}**</span>}
                  </div>


                </div>


              </div>

              <div className={Styles.ButtonContainer}>
                <RedButton type="Submit">Submit</RedButton>
              </div>
            </div>
          </form> */}
        </div>
      </Collapse>
    </>
  )
}

const Career = () => {
  const [list, setList] = useState([

    {
      jobTitle: "Flutter Developer",
      exp: "0-2",
      location: "Bangalore/Bengaluru",
      skills: ["Flutter", "Dart", "GIT"],
      Educational: "Graduate degree in Computer Science or equivalent",
      Responsibilities: [
        { title: "Strong knowledge of Dart programming language and the Flutter framework." },
        { title: "Proficiency in using Flutter widgets and third-party libraries to create rich user interfaces." },
        { title: "Familiarity with RESTful APIs and integration of backend services." },
        { title: "Solid understanding of mobile app development principles, including architecture patterns, data storage, and performance optimization." },
        { title: "Strong problem-solving skills and ability to work in a fast-paced environment." },
        { title: "Familiarity with version control systems, such as Git." },
        { title: "Troubleshoot and debug issues that arise during development, testing, and deployment." },
        { title: "Develop high-quality, scalable, and maintainable code using the Flutter framework." },
      ],
    }
    // ,
    // {
    //   jobTitle: "React JS developer",
    //   exp: "0-2",
    //   location: "Bangalore/Bengaluru",
    //   skills: ["HTML/CSS", "JavaScript", "Node", "React JS" , "React Framework", "Redux", "DOM", "Bootstrap", "Material-UI", "GIT"],
    //   Educational: "Graduate degree in Computer Science or equivalent",
    //   Responsibilities: [
    //     { title: "Strong proficiency in JavaScript and the React JS library." },
    //     { title: "Familiarity with modern frontend development tools and technologies, such as npm, webpack, and Babel." },
    //     { title: "Understanding of component-based architecture and state management using tools like Redux or Context API." },
    //     { title: "Knowledge of HTML, CSS, and CSS preprocessors like Sass or Less." },
    //     { title: "Understanding of version control systems, such as Git." },
    //     { title: "Collaborate with backend developers to integrate APIs and ensure smooth data flow between the frontend and backend." },
    //     { title: "Implement responsive user interfaces and interactive features based on design guidelines and specifications." }
    //   ],
    // }
  ])

 

  return (
    // <div>
    //     <div className={Styles.Header}>
    //       <label className={Styles.HeaderTitle}>Career</label>
    //     </div>
    // </div>
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='ContainerCareer'>

        <div className={Styles.Header}>
          <label className={Styles.HeaderTitle}>Career</label>
        </div>

         {/* <div className={Styles.CareerImage}>
              
         </div> */}

        <div className={Styles.Job}>
          {
            list.map((item, index) => (
              <div key={index} style={{ cursor: "pointer" }}>
                <CollapsableCard item={item} />
              </div>

            ))
          }

        </div>


      </div>
    </>
  )
}

export default Career