import { Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Styles from './Contact.module.css';
import { styled } from '@mui/material/styles';
import { FilePicker } from 'react-file-picker';
import { Input } from '@mui/material';
import './contact.scss';
import { flexbox } from '@mui/system';
import { SendEmailContact } from '../../actions/contactAction';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IMG from '../../assets/Bg2d.jpg';
import { AiOutlineMail } from 'react-icons/ai'
import axios from 'axios';



const RedButton = styled(Button)(({ theme }) => ({
  color: "white",   
  textTransform:"none",
  fontSize:"14px",
  padding:"10px 20px",
  backgroundColor: "#283c50",
  border: "2px solid #283c50",
  '&:hover': {
    backgroundColor: "#283c50",
  },
}));






// if (loading) {
//   return <div>Loading...</div>;
// } 



const Contact = () => {
  const [result, setResult] = useState({})
  const [error, setError] = useState({})
  const [obj, setObj] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  })

  const handleChange = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value
    })
  }

  const IsValid = () => {
    let success = true;
    let error = {}
    if (!obj.name.trim()) {
      error.name = "Name cannot be empty"
      success = false;
    }
    if (!obj.mobile.trim()) {
      error.mobile = "Mobile cannot be empty"
      success = false;
    }
    else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(obj.mobile)) {
      error.mobile = "Enter a Valid mobile Number";
      success = false
    }
    if (!obj.email.trim()) {
      error.email = "Email cannot be empty";
      success = false
    }
    else if (!(/^[\w-\+\.\_]+(\.[\w-\+\.\_]+)*@[\w-\+\.\_]+(\.[\w\+\.\_]+)*(\.[A-Za-z]{2,})$/).test(obj.email)) {
      error.email = "Enter a Valid Email";
      success = false
    }
    if (!obj.message.trim()) {
      error.message = "Message cannot be empty";
      success = false
    }

    setError(error);
    return success
  }

  const [sending,setSending]=useState(false)
  const Submit = async () => {
    if (IsValid()) {
let TOKEN = ''
setSending(true)
      let param = {
        "name": obj.name,
        "email": obj.email,
        "mobile": obj.mobile,
        "message": obj.message,
        "auth": process.env.REACT_APP_AUTH_KEY
      }
        try {
            const Data = await axios.post('https://4zfjt8khgh.execute-api.ap-south-1.amazonaws.com/echno-website/contact',param,{
              headers:{
                "Authorization" : `Bearer ${TOKEN}`
              }
             })

             const {data} = Data;
             if(data?.code===200){
              setObj({
                ...obj,
                name: '',
                email: '',
                message: '',
                mobile: ''
              })
              setResult({})
              setSending(false)
              toast.success("Mail Sent Successfully")
             }

             
            
            setError({})
             console.log(Data)
        } catch (error) {
           console.log(error);
           setSending(false)
           toast.success("Sending Email Failed")
        }
    }
  }

  let URL = `mailto:vijayravi324@gmail.com?subject=Echno%20Contact%20Us%20-&body=Full%20Name:%20[${obj.name}]%0AEmail:%20[${obj.email}]%0APhone%20Number:%20[${obj.mobile}]%0AMessage:%20[${obj.message}]`
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <>
      {/* <a href={URL}>Send Email</a> */}
       <div className='ContainerContact'>
        <div className={Styles.backGroundImg}>
          <img src={IMG} />


          <div className={Styles.ContactUsMidContainer}>
            <div className={Styles.SectionOne}>
              <div className={Styles.ChatCenter}>


                <div className={Styles.ChatHeading}>
                  <h1>Let's Connect</h1>
                </div>
                      {/* <div className={Styles.SubChatHeading}>
                        <h5>Let's create something together</h5>
                      </div> */}
                <div className={Styles.SectionOneIconSection}>
                  <div className={Styles.SectionOneIconSectionCard}>
                    <div className={Styles.IconContainer}>
                      <AiOutlineMail className={Styles.Icon} />
                    </div>

                    <div className={Styles.InfoContainer}>
                      <label className={Styles.InfoHeadingOne}>Mail Me at</label>
                      <label className={Styles.InfoHeadingTwo}>contact@echnotek.com</label>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div className={Styles.SectionTwo}>
              <div className={Styles.Card}>


                <label className={Styles.HeadingTwo}>Send us a message</label>

                <div className={Styles.InputList}>
                  <div className={Styles.ListContainer}>
                    <TextField label="Full Name" size='small' variant="outlined" name="name" autoComplete='off' value={obj.name} onChange={handleChange} />
                    {error.name && <span className={Styles.Error}>**{error.name}**</span>}
                  </div>

                  <TextField label="Email" size='small' variant="outlined" name='email' autoComplete='off' value={obj.email} onChange={handleChange} />
                  {error.email && <span className={Styles.Error}>**{error.email}**</span>}
                  <TextField label="Mobile No" size='small' variant="outlined" name='mobile' autoComplete='off' value={obj.mobile} onChange={handleChange} />
                  {error.mobile && <span className={Styles.Error}>**{error.mobile}**</span>}
                  <TextField label="Message" size='small' variant="outlined" name="message" autoComplete='off' value={obj.message} multiline rows={4} onChange={handleChange} />
                  {error.message && <span className={Styles.Error}>**{error.message}**</span>}

                  {/* <div style={{display:"flexbox",flexDirection:"row",gap:"10px"}}>
     <label style={{color:"rgba(0, 0, 0, 0.6)"}}>Upload Resume:</label><input type="file" variant="standard" className={Styles.Choose}/>
   </div> */}

                  <div>
                    <RedButton onClick={Submit} disabled={sending}>{sending ? "Sending Email...." : "Send message"}</RedButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      </>
     
    </>
  )
}

export default Contact



// <div className='ContainerContact'>


// <div className={Styles.ContactHeader}>
//   <label className={Styles.ContactHeaderTitle}>Contact Us</label>
// </div>

// <div className={Styles.container}>
//   <label className={Styles.Heading}>Voluptatem Accusantium Doloremque</label>
//   <p className={Styles.Para}>Sed ut perspiciaatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas</p>
//   <p className={Styles.Para}>
//     Sed ut perspiciaatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
//   </p>
//   <p className={Styles.Para}>
//     Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do tempor.
//   </p>

//   <label className={Styles.HeadingTwo}>Contact Me</label>

//   <div className={Styles.InputList}>
//     <div className={Styles.ListContainer}>
//       <TextField label="Full Name" size='small' variant="outlined" name="name" autoComplete='off' value={obj.name} onChange={handleChange} />
//       {error.name && <span className={Styles.Error}>**{error.name}**</span>}
//     </div>

//     <TextField label="Email" size='small' variant="outlined" name='email' autoComplete='off' value={obj.email} onChange={handleChange} />
//     {error.email && <span className={Styles.Error}>**{error.email}**</span>}
//     <TextField label="Mobile No" size='small' variant="outlined" name='mobile' autoComplete='off' value={obj.mobile} onChange={handleChange} />
//     {error.mobile && <span className={Styles.Error}>**{error.mobile}**</span>}
//     <TextField label="Message" size='small' variant="outlined" name="message" autoComplete='off' value={obj.message} multiline rows={4} onChange={handleChange} />
//     {error.message && <span className={Styles.Error}>**{error.message}**</span>}

//     {/* <div style={{display:"flexbox",flexDirection:"row",gap:"10px"}}>
//     <label style={{color:"rgba(0, 0, 0, 0.6)"}}>Upload Resume:</label><input type="file" variant="standard" className={Styles.Choose}/>
//   </div> */}

//     <div>
//       <RedButton onClick={Submit}>Send</RedButton>
//     </div>
//   </div>
// </div>

// </div>