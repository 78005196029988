import axios from 'axios';
import { baseUrl } from '../baseUrl';

const Authaxios = axios.create({
    baseURL:baseUrl,
    headers:{
        "Content-type":'multipart/form-data',
        
    }
})

export const SendEmailCareer=async(param)=>{
      
    return new Promise(async(resolve,reject)=>{
        try {
       const {data} =await Authaxios.post('echno-uploadResume',param);
       resolve(data)
   } catch (error) {
       reject(error);

   }
   })
}