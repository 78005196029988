import React from 'react'
import Styles from './Services.module.css'
import {BsBell} from 'react-icons/bs';
import {SiMicrostrategy} from 'react-icons/si';
import {GiReceiveMoney} from 'react-icons/gi';
import {FaRegLightbulb} from 'react-icons/fa';
import {BsArrowsAngleContract} from 'react-icons/bs';
import {BiSupport} from 'react-icons/bi';
import {HiOutlineLightBulb} from 'react-icons/hi'

const Services = () => {
  return (
    <div className={Styles.container}>
        <div className={Styles.One}>
            <label className={Styles.OneTitle}>WHAT WE OFFER</label>
            <p className={Styles.OneText}>We provide seamless layer of custom made software solutions to ensure increase in revenue and help grow your business. We offerings are in response to dynamic needs of the business of our customers.</p>
        </div>
        <div className={Styles.Two}>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><BsBell className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Consulting</label>
                    <p className={Styles.serviceContainerText}>We collaborate with our clients toward data and technology solutions by providing greater insights, improvement in processes and revenue growth which results in more effectiveness.</p>
                </div>
            </div>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><SiMicrostrategy className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Strategy</label>
                    <p className={Styles.serviceContainerText}>We make use of data to improve operations & strategic decision making for Enterprise Data Strategy. Data-Driven Decisions optimize the workflow and improves maturity of the product.</p>
                </div>
            </div>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><FaRegLightbulb className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Analysis</label>
                    <p className={Styles.serviceContainerText}>Predictive power can be unlocked using data science and machine learning. A flexible cloud architecture and data driven approach provides right and real time analysis.</p>
                </div>
            </div>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><HiOutlineLightBulb className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Ideation</label>
                    <p className={Styles.serviceContainerText}>Ideation needs creativity and UX solutions. We believe to walk with our clients from the concept stage and mark the journey towards success together.
</p>
                </div>
            </div>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><BsArrowsAngleContract className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Design</label>
                    <p className={Styles.serviceContainerText}>Modernize your data with cloud, flexible architecture and the latest technologies. Streamline business insights from data while reducing costs.</p>
                </div>
            </div>
            <div className={Styles.TwoList}>
                <div className={Styles.IconContainer}><BiSupport className={Styles.Icon}/></div>
                <div className={Styles.serviceContainer}>
                    <label className={Styles.serviceContainerTitle}>Development</label>
                    <p className={Styles.serviceContainerText}>We have a team of experts to build the best solutions providing latest technology solutions and cater these solutions and get the first time right solutions built for market.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services