import React from 'react'
import ImageSlider from '../../components/ImageSlider/imageslider'
import Organization from '../../components/organization/Organization'
import Services from '../../components/services/services';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { RiDoubleQuotesR } from 'react-icons/ri';
import Styles from './home.module.css';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GreenButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#00cc66",
  border: "2px solid #00cc66",
  '&:hover': {
    backgroundColor: "#00cc66",
  },
}));

const Home = () => {
  const navigate = useNavigate()
  return (
    <div>

      <ImageSlider />

      <div className={Styles.quotesContainer}>
        <label className={Styles.quotes}>
          <RiDoubleQuotesL className={Styles.QuoteIcon} />
          We are a custom software development company empowering Digital Transformation by way of providing data and technology solution to our clients. We believe that digital transformation integrated with right data engineering, analytics and machine learning solutions accelerate creativity and growth to 360° extent.  <RiDoubleQuotesR className={Styles.QuoteIconTwo} />
        </label>
      </div>
      <Services />

      {/* <div className={Styles.CardContainer}>
        <div className={Styles.Card}>
          <label>
            Business Intelligence
          </label>

          <p>
          BI basically extract important insights from vast unstructured data & transform them into valuable business information by using software to transform raw data. It combines data mining, data visualization, sophisticated data analytics toolings and business analysis to help organizations to optimize growth possibilities and make more data-driven decisions.
          </p>

          <p>
          As BI service providers, we offer solution to our clients covering all aspects of business intelligence from implementation or migration to a scalable and robust solution that covers organizations data needs and operational challenges.
          </p>
        </div>
      </div> */}
      <Organization />

      {/* <div className={Styles.CareerSection}>
        <div className={Styles.CareerImage}>
        </div>
        <div className={Styles.CareerInfo}>
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingOne}>Life @Echno</label>
          </div>
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingTwo}>Make an Impact</label>
          </div> 
          <div className={Styles.CareerList}>
            <label className={Styles.CareerheadingThree}>We focus on investing on our employees, retain them, nurture a creative environment & help them find a way to innovate</label>
          </div>

          <div className={Styles.CareerButton}>
              <GreenButton onClick={()=>navigate('\career')}>Join Us</GreenButton>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default Home