import React, { useState, useEffect } from 'react';
import Styles from './navbar.module.css';
import logo from '../../assets/et.png';
import logo1 from '../../assets/LogoEchno.png';
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai';
import {NavLink, useLocation} from 'react-router-dom';
import {useNavigate } from 'react-router-dom';



const Navbar = () => {
    const location = useLocation();
    const [toggle, setToggle] = useState(false);
   
    return (
        <div className={Styles.container}>
            <div className={Styles.navbarlogo}>
                <img src={logo1} alt="logo" className={Styles.navbarlogoimg} />
            </div>

            <ul className={Styles.navbar_links}>
                <li className={Styles.navbar_link}><NavLink to='/' className={location.pathname.toLocaleLowerCase() === '/' ? Styles.navbar_link_Active : Styles.navbar_link_NotActive}>HOME</NavLink></li>
                <li className={Styles.navbar_link}><NavLink to='/about' className={location.pathname.toLocaleLowerCase() === '/about' ? Styles.navbar_link_Active : Styles.navbar_link_NotActive}>ABOUT</NavLink></li>
                <li className={Styles.navbar_link}><NavLink to='/services' className={location.pathname.toLocaleLowerCase() === '/services' ? Styles.navbar_link_Active : Styles.navbar_link_NotActive}>SERVICES</NavLink></li>
                <li className={Styles.navbar_link}><NavLink to='/career' className={location.pathname.toLocaleLowerCase() === '/career' ? Styles.navbar_link_Active : Styles.navbar_link_NotActive}>CAREER</NavLink></li>
                <li className={Styles.navbar_link}><NavLink to='/contactus' className={location.pathname.toLocaleLowerCase() === '/contactus' ? Styles.navbar_link_Active : Styles.navbar_link_NotActive}>CONTACT US</NavLink></li>
            </ul>

            <div className={Styles.navbar_smallscreen}>
                <GiHamburgerMenu color='#000' fontSize={27} onClick={() => setToggle(true)} />
                {
                    toggle && (
                        <div className={[Styles.navbar_smallscreen_overlay, Styles.slide_bottom, Styles.flexCenter].join(' ')}>
                            <AiOutlineClose fontSize={27} className={Styles.overlay_close} onClick={() => setToggle(false)} />

                            <ul className={Styles.navbar_smallscreen_links}>
                                <li className={Styles.navbar_link} onClick={() => setToggle(false)}><NavLink to='/' className={location.pathname.toLocaleLowerCase() === '/' ? Styles.navbar_link_Active_Small : Styles.navbar_link_NotActive}>HOME</NavLink></li>
                                <li className={Styles.navbar_link} onClick={() => setToggle(false)}><NavLink to='/about' className={location.pathname.toLocaleLowerCase() === '/about' ? Styles.navbar_link_Active_Small : Styles.navbar_link_NotActive}>ABOUT</NavLink></li>
                                <li className={Styles.navbar_link} onClick={() => setToggle(false)}><NavLink to='/services' className={location.pathname.toLocaleLowerCase() === '/services' ? Styles.navbar_link_Active_Small : Styles.navbar_link_NotActive}>SERVICES</NavLink></li>
                                <li className={Styles.navbar_link} onClick={() => setToggle(false)}><NavLink to='/career' className={location.pathname.toLocaleLowerCase() === '/career' ? Styles.navbar_link_Active_Small : Styles.navbar_link_NotActive}>CAREER</NavLink></li>
                                <li className={Styles.navbar_link} onClick={() => setToggle(false)}><NavLink to='/contactus' className={location.pathname.toLocaleLowerCase() === '/contactus' ? Styles.navbar_link_Active_Small : Styles.navbar_link_NotActive}>CONTACT US</NavLink></li>
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Navbar