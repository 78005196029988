// privacy page 
import React from 'react'
import "./PrivacyPolicy.css"
const PrivacyPolicy = () =>{
    return (
        <div className='container'>
        <div><h1>Privacy Policy</h1></div>
        <div className='center-content'>
        <p>Welcome to www.echnotek.com ("we," "us," or "our"). Your privacy is important to us, and we are committed to protecting the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website and services.</p>


        <p><b>1. Information We Collect</b></p>
        <p>1.1 Personal Information:
        Contact Information: We may collect your name, email address, phone number, and mailing address.


        Account Information: If you create an account with us, we collect and store your login credentials and other account-related information.<br></br>


        1.2 Automatically Collected Information:
        Log Data: We automatically collect information about your device, browser type, IP address, and pages visited.


        Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience and collect data about your interactions with our website.</p><br></br>


        <p><b>2. How We Use Your Information</b></p>
        <p>We use the collected information for the following purposes:


        Providing Services: To deliver and maintain our services, personalize your experience, and improve our offerings.


        Communications: To communicate with you about our products, services, promotions, and respond to your inquiries.


        Analytics: To analyze how our website is used and improve its functionality.</p>


        <p><b>3. Information Sharing and Disclosure</b></p>
       <p> We do not sell, trade, or rent your personal information to third parties. We may share your information in the following circumstances:


        Service Providers: We may share information with third-party service providers who assist us in providing and improving our services.


        Legal Compliance: We may disclose information if required by law or in response to a legal process.</p>


        <p><b>4. Your Choices and Rights</b></p>
        <p>Access, Correction, or Deletion: You have the right to access, correct, or request the deletion of your personal information.


        Opt-out: You can opt-out of certain data collection and processing activities.</p>


        <p><b>5. Security</b></p>
        <p>We are committed to ensuring the security of your information. We implement reasonable measures to protect against unauthorized access, disclosure, alteration, and destruction.</p>


        <p><b>6. Changes to This Privacy Policy</b></p>
        <p>We may update this Privacy Policy periodically. The updated policy will be effective immediately upon posting on our website.</p>

        
        <p><b>7. Contact Us</b></p>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@echnotek.com.</p>
        </div>
        </div>
    )
}
export default PrivacyPolicy