import React from 'react';
import Styles from './Organization.module.css';
import Person from '../../assets/organization.jpg';
import {IoIosArrowDropright} from 'react-icons/io'

const Organization = () => {
  return (
    <div className={Styles.container}>
        <div className={Styles.One}>
        
            <label className={Styles.OneTitle}>Business Intelligence</label>
            <p className={Styles.OneText}>BI basically extract important insights from vast unstructured data & transform them into valuable business information by using software to transform raw data. It combines data mining, data visualization, sophisticated data analytics toolings and business analysis to help organizations to optimize growth possibilities and make more data-driven decisions.</p>

            <p className={Styles.OneText}>Business world today has reached to a level where data becomes most versatile part of decision and strategy making. organizations collect & store huge amount of data from various sources and solutions. There is a need mainly to structure Business strategies based on data driven facts. With Business Intelligence solutions, we help our customers not only to optiize data storage and mining, but also automate the processes to a large extent.</p>
        </div>
        <div className={Styles.Two}>
            <div className={Styles.about}>
            <div>
                <p className={Styles.aboutText}>
                As BI service providers, we offer solution to our clients covering all aspects of business intelligence from implementation or migration to a scalable and robust solution that covers organizations data needs and operational challenges.
                 </p>
            </div>
            <div className={Styles.listContainer}>
                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Data Warehousing</label></div>
                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Data Integration</label></div>
                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Data Visualization</label></div>
                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Data Migration</label></div>

                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Effective Business Model</label></div>
                <div className={Styles.list}><IoIosArrowDropright className={Styles.listIcon}/><label className={Styles.listText}> Customer Insights</label></div>
            </div>

            {/* <div className={Styles.buttonContainer}>
                <button className={Styles.Button}>Learn More</button>
            </div> */}
                 
            </div>
            <div className={Styles.ImgContainer}>
                <img src={Person} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Organization